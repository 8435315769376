import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { EnumVariacoesDadosEspecificos } from 'src/app/models/api/dados-especificos/enum-variacoes-dados-especificos.enum';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { map, shareReplay, throwIfEmpty } from 'rxjs/operators';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { ProductBaseEntity } from './product-base-entity.class';
import { CanSubmitForReview } from './interfaces';
import { ApiDadosEspecificosService } from 'src/app/services/api/dados-especificos/api-dados-especificos.service';
import { SpecificDataForm } from '../models/specific-data-form';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { validateEanDun } from 'src/app/shared/validators/ean-dun';
import { OnlineEanValidator } from '../helpers/online-ean.validator';
import { CorGrade } from 'src/app/models/api/dados-especificos/cor-grade';
import { ApiCorGradeService } from 'src/app/services/api/dados-especificos/api-cor-grade.service';
import { TamanhoGrade } from 'src/app/models/api/dados-especificos/tamanho-grade';
import { ApiTamanhoGradeService } from 'src/app/services/api/dados-especificos/api-tamanho-grade.service';

@Injectable()
export class ProductSpecificDataService extends ProductBaseEntity implements CanSubmitForReview {

  @IsAllowed('rhcdm.dadosespecificos.editarFinalizado') canSaveFinished: boolean;
  @IsAllowed('rhcdm.dadosespecificos.editarRevisao') canSaveReview: boolean;
  @IsAllowed('rhcdm.dadosespecificos.editarReprovado') canSaveRepproved: boolean;
  @IsAllowed('rhcdm.dadosespecificos.aprovar') canApproveOrReprove: boolean;

  regexDisallowEmptyString = /^(?!\s*$).+/;

  removeVariation$ = new Subject<number>();

  // API CACHED DATA
  departamentos$: Observable<Departamento[]>;
  categorias$: Observable<Categoria[]>;
  subniveis$: Observable<Subnivel[]>;
  corGrade$: Observable<CorGrade[]>;
  tamanhoGrade$: Observable<TamanhoGrade[]>;
  canUpdatePDM: boolean = true;

  untouchableLinks: string[] = [];

  // Dados de integrações
  // dados são gravadas assim que produto é pego pelo id
  sortimentosIntegrationData: {
    [id: string]: {
      codigoSap?: string;
      idVtex?: string;
      sku?: string;
    }
  } = {};

  gradeIntegrationData: {
    [id: string]: {
      codigoSap?: string;
      idVtex?: string;
      sku?: string;
    }
  } = {};

  constructor(
    fb: FormBuilder,
    private toastService: ToastService,
    private api: ApiDadosEspecificosService,
    private apiDepartamento: ApiDepartamentoService,
    private apiCategoria: ApiCategoriaService,
    private apiSubnivel: ApiSubnivelService,
    private onlineEanValidator: OnlineEanValidator,
    public apiCorGrade: ApiCorGradeService,
    public apiTamanhoGrade: ApiTamanhoGradeService,
  ) {
    super(fb);
    this.initForm();

    /**
     * O SHARE REPLAY funciona como um cache, a request é feita apenas uma vez,
     * mesmo que seja criado uma nova subscription para o observable
     */
    this.departamentos$ = this.apiDepartamento.getAll().pipe(map(response => response.data), shareReplay(1));
    this.categorias$ = this.apiCategoria.getAll().pipe(map(response => response.data), shareReplay(1));
    this.subniveis$ = this.apiSubnivel.getAll().pipe(map(response => response.data), shareReplay(1));
    this.corGrade$ = this.apiCorGrade.getAll().pipe(map(response => response.data), shareReplay(1));
    this.tamanhoGrade$ = this.apiTamanhoGrade.getAll().pipe(map(response => response.data), shareReplay(1));
    // this.listenLongDescriptionAndExtendedDescriptionChanges();
  }

  setValue(productId: string, status: EnumStatus, value: SpecificDataForm) {
    super.setValue(productId, status, value);

    if (value && value.sortimentoList) {
      const sortimentoListControl = this.form.get('sortimentoList') as FormArray;
      value.sortimentoList.forEach((s, i) => {
        const form = this.createSortimentoForm();
        form.patchValue(s);
        sortimentoListControl.insert(i, form);
      });
    }

    if (value && value.gradeList) {
      const gradeListControl = this.form.get('gradeList') as FormArray;
      value.gradeList.forEach(((g, i) => {
        const form = this.createGradeForm();
        form.patchValue(g);
        gradeListControl.insert(i, form);
      }));
    }
  }

  // Métodos auxiliares do formulário

  addVariacao() {
    const grade = this.createGradeForm();
    this.gradeListForm.push(grade);
  }

  addSortimento() {
    const sortimento = this.createSortimentoForm();
    this.sortimentoListForm.push(sortimento);
  }

  removeVariacao(index: number) {
    this.gradeListForm.removeAt(index);
    this.removeVariation$.next(index+1);
  }

  removeSortimento(index: number) {
    this.sortimentoListForm.removeAt(index);
    this.removeVariation$.next(index+1);
  }

  enablePdmForLongDescription() {
    const hasValue = this.descricaoLonga.value;

    if (!hasValue) {
      this.descricaoLongaPersonalizada.setValue(true);
    }
  }

  disablePdmForLongDescription() {
    const hasValue = this.descricaoLonga.value;

    if (hasValue) {
      this.descricaoLongaPersonalizada.setValue(false);
    }
  }

  enablePdmForExtendedDescription() {
    const hasValue = this.descricaoExtendida.value;

    if (!hasValue) {
      this.descricaoExtendidaPersonalizada.setValue(true);
    }
  }

  disablePdmForExtendedDescription() {
    const hasValue = this.descricaoExtendida.value;

    if (hasValue) {
      this.descricaoExtendidaPersonalizada.setValue(false);
    }
  }

  enablePdmForTituloDePublicacao() {
    const hasValue = this.tituloDePublicacao.value;

    if (!hasValue) {
      this.tituloDePublicacaoPersonalizada.setValue(true);
    }
  }

  disablePdmForTituloDePublicacao() {
    const hasValue = this.tituloDePublicacao.value;

    if (hasValue) {
      this.tituloDePublicacaoPersonalizada.setValue(false);
    }
  }

  enablePdmForDescricaoEcommerce() {
    const hasValue = this.dadosDoEcommerce.get('nomeDoProduto').value;

    if (!hasValue) {
      this.descricaoEcommercePersonalizada.setValue(true);
    }
  }

  disablePdmForDescricaoEcommerce() {
    const hasValue = this.dadosDoEcommerce.get('nomeDoProduto').value;
    console.log(hasValue)
    if (hasValue) {
      this.descricaoEcommercePersonalizada.setValue(false);
    }
  }

  setUntouchableLinks(dadosEspecificos: any){
    
    if(dadosEspecificos == null)
      return;

      
    let untouchableLinks = [];

    if(dadosEspecificos.dadosDoEcommerce != null 
      && dadosEspecificos.dadosDoEcommerce.link != null 
      && dadosEspecificos.dadosDoEcommerce.link != ""){
        untouchableLinks.push(dadosEspecificos.dadosDoEcommerce.link);
    }

    if(dadosEspecificos.sortimentoList != null && dadosEspecificos.sortimentoList.length > 0){

      dadosEspecificos.sortimentoList.forEach(sortimento => {

        if(sortimento.dadosDoEcommerce != null
          && sortimento.dadosDoEcommerce.link != null 
          && sortimento.dadosDoEcommerce.link != ""){
            untouchableLinks.push(sortimento.dadosDoEcommerce.link);
        }

      })

    }

    this.untouchableLinks = [...untouchableLinks];
  }

  // Criação do Formulário

  createForm() {

    return this.fb.group({
      departamentoId: [undefined, Validators.required],
      categoriaId: [undefined, Validators.required],
      subnivelId: [undefined, Validators.required],

      objetoTemplateSubnivel: [undefined, Validators.required],

      descricaoLonga: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      descricaoExtendida: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      tituloDePublicacao: [undefined, [Validators.maxLength(60)]],
      // descricaoCurta: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      // descricaoAbreviada: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      descricaoLongaPersonalizada: [true],
      descricaoExtendidaPersonalizada: [true],
      tituloDePublicacaoPersonalizada: [true],
      descricaoEcommercePersonalizada: [true],

      dadosDoEcommerce: this.createDadosDoEcommerce(),

      variacoes: [EnumVariacoesDadosEspecificos.Nenhum, Validators.required],
      sortimentoList: this.createSortimentoListForm(),
      gradeList: this.createGradeListForm(),
    });
  }

  private createSortimentoListForm() {
    return this.fb.array([]);
  }

  private createGradeListForm() {
    return this.fb.array([]);
  }

  createSortimentoForm() {
    return this.fb.group({
      sortimentoId: [undefined],
      itemSortido: [undefined, Validators.required],
      cor: [undefined, Validators.required],
      descricaoLongaSortimento: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      descricaoEstendidaSortimento: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      tituloDePublicacaoSortimento: [undefined, [Validators.maxLength(60)]],
      descricaoLongaSortimentoPersonalizada: [true],
      descricaoEstendidaSortimentoPersonalizada: [true],
      tituloDePublicacaoSortimentoPersonalizada: [true],
      descricaoEcommerceSortimentoPersonalizada: [true],
      referencia: [undefined, Validators.required],
      dadosDoEcommerce: this.createDadosDoEcommerce(),
    });
  }

  createGradeForm() {
    return this.fb.group({
      gradeId: [undefined],
      ean: [undefined, validateEanDun()],
      tamanho: [undefined, Validators.required],
      cor: [undefined, Validators.required],
      nomeDoProduto: [undefined, Validators.required],
      descricaoLongaGrade: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      descricaoEstendidaGrade: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      tituloDePublicacaoGrade: [undefined, [Validators.maxLength(60)]],
      descricaoLongaGradePersonalizada: [true],
      descricaoEstendidaGradePersonalizada: [true],
      tituloDePublicacaoGradePersonalizada: [true],
      descricaoEcommerceGradePersonalizada: [true],
      referencia: [undefined, Validators.required],
      dadosDoEcommerce: this.createDadosDoEcommerce(),
    });
  }

  private createDadosDoEcommerce() {
    return this.fb.group({
      nomeDoProduto: [undefined, [Validators.maxLength(100), Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      descricaoNarrativa: [undefined, [Validators.required, Validators.pattern(this.regexDisallowEmptyString)]],
      link: [undefined]
    });
  }

  // manipulação formulário

  enableDadosDoEcommerceGrade(){
    this.dadosDoEcommerce.get('descricaoNarrativa').reset();
    this.dadosDoEcommerce.get('descricaoNarrativa').disable();
    this.dadosDoEcommerce.get('link').reset(null);
    this.dadosDoEcommerce.get('nomeDoProduto').setValidators([Validators.required, Validators.maxLength(100)]);
    this.dadosDoEcommerce.get('nomeDoProduto').updateValueAndValidity();
  }

  enableDadosDoEcommerce(){
    this.dadosDoEcommerce.enable();
    this.dadosDoEcommerce.get('nomeDoProduto').setValidators(Validators.maxLength(100));
    this.dadosDoEcommerce.get('nomeDoProduto').updateValueAndValidity();
  }

  // helpers de acesso aos dados do form

  get sortimentoListForm() {
    return this.form && this.form.get('sortimentoList') as FormArray;
  }

  get gradeListForm() {
    return this.form && this.form.get('gradeList') as FormArray;
  }

  get dadosDoEcommerce() {
    return this.form && this.form.get('dadosDoEcommerce') as FormGroup;
  }

  get variacoes() {
    return this.form && this.form.get('variacoes');
  }

  get descricaoLonga() {
    return this.form && this.form.get('descricaoLonga');
  }

  get descricaoExtendida() {
    return this.form && this.form.get('descricaoExtendida');
  }

  get tituloDePublicacao() {
    return this.form && this.form.get('tituloDePublicacao');
  }

  get descricaoLongaPersonalizada() {
    return this.form && this.form.get('descricaoLongaPersonalizada');
  }

  get descricaoExtendidaPersonalizada() {
    return this.form && this.form.get('descricaoExtendidaPersonalizada');
  }

  get tituloDePublicacaoPersonalizada() {
    return this.form && this.form.get('tituloDePublicacaoPersonalizada');
  }

  get descricaoEcommercePersonalizada() {
    return this.form && this.form.get('descricaoEcommercePersonalizada');
  }

  // Can do something

  get canUsePdmForDescricaoLonga() {
    return this.descricaoLongaPersonalizada.value;
  }

  get canUsePdmForDescricaoExtendida() {
    return this.descricaoExtendidaPersonalizada.value;
  }

  get canUsePdmForTituloDePublicacao() {
    return this.tituloDePublicacaoPersonalizada.value;
  }

  get canUsePdmForDescricaoEcommerce() {
    return this.descricaoEcommercePersonalizada.value;
  }

  linkConvertionFn(description: string) {
    return description
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/-/g, '')
    .replace(/\s\s+/g, ' ')
    .trim()
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, "")
    .toLowerCase();
  }

}
